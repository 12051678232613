import React from 'react';
import { Title1 } from '../../../atoms/headers';
import { Text3 } from '../../../atoms/body';
import { Shape, IronhackLogo, Header } from './styles';
import Layout from '../../../atoms/layout';
import { getLinkByPageName } from '../../../../lib/links';

interface HeaderProps {
  title: string;
  subtitle?: string;
}

const CleanHeader: React.FunctionComponent<HeaderProps> = ({
  title,
  subtitle
}: HeaderProps) => (
  <Layout pt="1.6rem">
    <Shape />
    <a href={getLinkByPageName('home')} alt="Ironhack Logo">
      <IronhackLogo />
    </a>
    <Header>
      <Title1 as="h1">{title}</Title1>
      {subtitle && <Text3 as="h2">{subtitle}</Text3>}
    </Header>
  </Layout>
);

export default CleanHeader;
