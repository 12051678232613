import { mapValues } from 'lodash';
import { TOptions } from 'i18next';
import { TFunction } from 'react-i18next';

type Node = Tree;
// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
interface Tree {
  [x: string]: Node | string;
}

const translateKeyMap = <Type extends Tree>(
  keys: Type,
  t: TFunction<string> | TFunction<string[]>,
  ops: TOptions = {}
): Type =>
  mapValues(keys, (v, k) => {
    if (typeof v === 'object' && !Array.isArray(v) && v !== null) {
      return translateKeyMap(v, t, ops[k]);
    }
    if (ops[k]) return t(v, ops[k]);
    return t(v);
  });

export default translateKeyMap;
