import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
} from '@chakra-ui/react';
import { useClerk, useSignIn } from '@clerk/clerk-react';

import { AdmissionsMessage } from '../../../containers/ApplyForm/ApplyWithCohort/Components';
import { usePageData } from '../../../context/PageData';
import type { PageData } from '..';
import ErrorCard from '../../../containers/ApplyForm/experiments/StepForms/ErrorCard';

type SignUpInputs = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  cohort: string;
  lead: string;
  sfObjectId: string;
  sfObjectType: string;
  hasAccount: boolean;
};

type Props = {
  applicant: Omit<SignUpInputs, 'password'>;
};

const apiUrl = process.env.IRONHACK_API?.slice(0, -8) as string;
const studentPortalUrl = process.env.GATSBY_STUDENT_PORTAL_URL as string;

const CreateAccount = (props: Props): React.ReactElement => {
  const { applicant: defaultValues } = props;
  const pageData = usePageData() as PageData;

  const { signIn } = useSignIn();
  const { setSession } = useClerk();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignUpInputs>({ defaultValues });
  const [submitError, setSubmitError] = useState('');
  const [isAccountCreated, setIsAccountCreated] = useState(false);

  const onSubmit: SubmitHandler<SignUpInputs> = async (values) => {
    setSubmitError('');
    const {
      firstName,
      lastName,
      email,
      password,
      cohort,
      sfObjectId,
      sfObjectType,
    } = values;
    const applicant = {
      cohort,
      email,
      first_name: firstName,
      last_name: lastName,
      password,
      sf_object_id: sfObjectId,
      sf_object_type: sfObjectType,
    };
    const response = await fetch(`${apiUrl}/applicants`, {
      body: JSON.stringify(applicant),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    });

    if (response.status >= 400 && response.status < 600) {
      setSubmitError(pageData.formSubmitErrorMessage);
    } else {
      const signInResource = await signIn.create({
        identifier: values.email,
        password: values.password,
      });

      await setSession(signInResource.createdSessionId, () => {
        window.open(studentPortalUrl, '_blank', 'noopener,noreferrer');
      });

      setIsAccountCreated(true);
    }
  };

  return (
    <Flex
      bg="white"
      borderRadius="card"
      direction="column"
      justifyContent="center"
      m="0 auto"
      maxW="47rem"
      p={16}
      shadow="active2"
    >
      <AdmissionsMessage />
      {(isAccountCreated || defaultValues.hasAccount) && (
        <Button
          _hover={{ textDecoration: 'none' }}
          as={Link}
          href={studentPortalUrl}
          isExternal
          mt={4}
          w="full"
        >
          {pageData.buttonCta}
        </Button>
      )}
      {!isAccountCreated && (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={errors.password} mt={3}>
            <FormLabel htmlFor="password">
              {pageData.passwordFieldLabel}
            </FormLabel>
            <Input
              id="password"
              placeholder="*********"
              type="password"
              {...register('password', {
                required: 'Enter a password',
                minLength: {
                  value: 8,
                  message: pageData.passwordFieldErrorMessage,
                },
              })}
            />
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>
          <Button isLoading={isSubmitting} mt={4} type="submit" w="full">
            {pageData.buttonCta}
          </Button>
          {submitError && (
            <ErrorCard message={pageData.formSubmitErrorMessage} mt={4} />
          )}
        </form>
      )}
    </Flex>
  );
};

export default CreateAccount;
