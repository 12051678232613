import React, { useEffect, useState } from 'react';
import { graphql, Link as GatsbyLink } from 'gatsby';
import { Box, Grid, GridItem, Link, Text } from '@chakra-ui/react';
import { useLocation } from '@reach/router';
import { ClerkLoaded } from '@clerk/clerk-react';

import withI18next from '../../i18n/TemplateI18n';
import { GetApplicationData } from '../../storage/provider';
import Deindex from '../../components/Deindex';
import ThanksForApplyingProps from '../../interface/template';
import FrequentAskedQuestions from '../../components/FAQ';
import CleanHeader from '../../components/_molecules/headers/CleanHeader';
import translateKeyMap from '../../utils/translate';
import { loadInstapageScript } from '../../lib/instapage';
import CreateAccount from './components/CreateAccount';
import { PageDataProvider } from '../../context/PageData';
import { TDatoCmsImage } from '../../types/datocms';

export type PageData = {
  admissionsManagerMessage: string;
  admissionsManagerPicture: TDatoCmsImage;
  buttonCta: string;
  passwordFieldLabel: string;
  passwordFieldErrorMessage: string;
  formSubmitErrorMessage: string;
};

const translateKeys = {
  title: {
    main: 'application-form-thanks:applicationFormThanks.mainHeadline',
    subtitle: 'application-form-thanks:applicationFormThanks.subtitle',
  },
  faq: {
    title: 'application-form-thanks:applicationFormThanks.faqSectionTitle.main',
    highlight:
      'application-form-thanks:applicationFormThanks.faqSectionTitle.hightlighted',
    footer: {
      description: 'course-campus:courseCampus.faq.wantMore',
      link: 'course-campus:courseCampus.faq.linkText',
    },
  },
};

const ThanksForApplying: React.FC<ThanksForApplyingProps> = (
  props: ThanksForApplyingProps
) => {
  const { t, pageContext } = props;
  const { locale } = pageContext;
  const location = useLocation();
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    course: '',
    campus: '',
    timezone: '',
  });
  const [instapageScriptLoaded, setInstapageScriptLoaded] = useState(false);

  const applicant = location.state?.applicant;

  useEffect(() => {
    const applicationData = GetApplicationData();
    setUserData(applicationData);
  }, []);

  const copy = translateKeyMap(translateKeys, t, {
    title: { main: { firstName: userData.name } },
  });

  useEffect(() => {
    if (location.state?.instapageLandingId) {
      loadInstapageScript(() => {
        setInstapageScriptLoaded(true);
      });
    }
  }, [location.state?.instapageLandingId]);

  return (
    <PageDataProvider data={props.data.allDatoCmsThankYouPage.nodes[0]}>
      <ClerkLoaded>
        <Deindex />
        <CleanHeader subtitle={copy.title.subtitle} title={copy.title.main} />
        {applicant?.allowAccountCreation && (
          <CreateAccount applicant={applicant} />
        )}
        <Grid
          gap={6}
          mx={[3, null, 8]}
          my={4}
          templateColumns={['1fr', null, 'repeat(12, 1fr)']}
        >
          <GridItem colSpan={[1, null, 8, 6]} colStart={[0, null, 3, 4]}>
            <Text as="h2" textAlign={['left', 'center']} textStyle="title2">
              {copy.faq.title}
              <Box as="span" color="orange.100">
                {copy.faq.highlight}
              </Box>
            </Text>
            <FrequentAskedQuestions category="apply-thanks" />
            <Text textStyle="body4">
              <Box as="span" color="darkBlues.64">
                {copy.faq.footer.description}
              </Box>{' '}
              <Link as={GatsbyLink} to={`/${locale}/faq`} variant="emphasis">
                {copy.faq.footer.link}
              </Link>
            </Text>
          </GridItem>
        </Grid>
        {location.state?.instapageLandingId && instapageScriptLoaded && (
          <iframe
            className="instapage-noscrap"
            height="0px"
            name="instapage-local-storage"
            onLoad={() =>
              window.InstapageExternalConversion(
                location.state.instapageLandingId
              )
            }
            src="https://d3mwhxgzltpnyp.cloudfront.net/local-storage/index.html"
            style={{ display: 'block' }}
            title="instapage"
            width="0px"
          />
        )}
      </ClerkLoaded>
    </PageDataProvider>
  );
};

export const query = graphql`
  query ($locale: String!, $datoLocale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(menu)|(application-form-thanks)|(seo)|(breadcrumbs)|(banner)|(course-campus)/"
        }
      }
    ) {
      ...TranslationFragment
    }
    allDatoCmsThankYouPage(filter: { locale: { eq: $datoLocale } }) {
      nodes {
        admissionsManagerMessage {
          value
        }
        admissionsManagerPicture {
          alt
          title
          gatsbyImageData
        }
        buttonCta
        passwordFieldLabel
        passwordFieldErrorMessage
        formSubmitErrorMessage
      }
    }
  }
`;

export default withI18next()(ThanksForApplying);
