const loadInstapageScript = (callback: () => void): void => {
  const existingScript = document.querySelector('#instapage');
  if (!existingScript) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src =
      'https://storage.googleapis.com/instapage-assets/conversion-external.js';
    script.id = 'instapage';
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      if (callback) callback();
    });
  }
  if (existingScript && callback) callback();
};

export { loadInstapageScript };
