import styled from 'styled-components';
import config from '../../../config';
import BlueShape from '../../../../../public/assets/headers/blue-shape.svg';
import Logo from '../../../../../static/assets/icons/ironhack_logos/logo.svg';

export const IronhackLogo = styled(Logo)`
  display: block;
  margin: 0 auto 1.6rem;

  @media (min-width: ${config.resolutions.tablet}) {
    margin: unset;
  }
`;

export const Shape = styled(BlueShape)`
  display: none;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: auto;

  @media (min-width: ${config.resolutions.tablet}) {
    display: inline;
  }
`;

export const Header = styled.header`
  text-align: center;

  & > h1 {
    font-weight: 700;
    margin-bottom: 1.2rem;
  }

  & > h2 {
    color: ${config.palette.darkBlueTransparent('0.72')};
    font-weight: 400;
    margin-bottom: 3.2rem;
  }

  @media (min-width: ${config.resolutions.tablet}) {
    max-width: 76.8rem;
    margin: 0 auto 3.2rem;
  }
`;
